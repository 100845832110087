import _ from 'lodash';
import { timeFrameWords } from '../../pages/usage-dashboard/helpers';
import { UIConfig } from './interfaces';
import { CCApiService } from './api-service';

export class UsageService {
  static ApiService: CCApiService;
  static usageApi: string;
  static customerId: string;

  static init(config: UIConfig, customerId) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.customerId = customerId;
    this.usageApi = `${config.apiBasePath}/analytics/${customerId}/usage/query`;
  }

  static async getUsage(startDate: Date, endDate: Date, appType, timeRange, analyticsData = 'false') {
    const fetchOptions = {
      method: 'GET',
    };

    const queryParams = {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      application_type: appType,
      ...(analyticsData === 'false' && { analyticsData }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (timeRange) {
      _.set(queryParams, 'defined_range', timeFrameWords[timeRange]);
    }

    return this.ApiService.performFetch(`${this.usageApi}?${new URLSearchParams(queryParams)}`, fetchOptions).then(
      result => result
    );
  }
}
