import _ from 'lodash';
export const getAdjustedDate = (timeRange, date: Date, adjustBackward = true) => {
  const multiple = adjustBackward ? 1 : -1;
  const adjustedDate = new Date(date);
  switch (timeRange) {
    case '1H':
      adjustedDate.setHours(date.getHours() - 1 * multiple);
      break;
    case '1D':
      adjustedDate.setDate(date.getDate() - 1 * multiple);
      break;
    case '1W':
      adjustedDate.setDate(date.getDate() - 7 * multiple);
      break;
    case '1M':
      adjustedDate.setMonth(date.getMonth() - 1 * multiple);
      break;
    case '1Y':
      adjustedDate.setFullYear(date.getFullYear() - 1 * multiple);
      break;
  }
  return adjustedDate;
};

export const usecaseChartConfig = {
  type: 'line',
  forceRecreateChart: true,
  guide: {
    x: { label: { text: 'Time', padding: 40 } }, // custom label for X axis
    y: { label: { text: 'Score', padding: 40 } }, // custom label for Y axis
    showGridLines: '',
    color: {
      brewer: {
        search: '#01EFE2',
        searchahead: '#F48EA1',
        similarArticles: '#F48EA1',
        browse: '#8576FD',
        similarCases: '#8576FD',
        detail: '#F7EB8E',
        sme: '#F7EB8E',
        getmetatags: '#2CE374',
      },
    },
    interpolate: 'linear',
  },
  settings: {
    xTickWidth: 30,
    yTickWidth: 30,
  },
  color: 'usecase',
  pluginsConfig: [
    {
      name: 'tooltip',
      config: {
        fields: ['score', 'time', 'usecase'],
        clickable: false,
        formatters: { score: { label: 'score' }, time: { label: 'time' }, usecase: { label: 'use case' } },
      },
    },
    { name: 'legend', config: { position: 'bottom', formatters: { usecase: {} } } },
  ],
  x: 'time',
  y: 'score',
  dimensions: { score: { type: 'measure' }, time: { type: 'measure', scale: 'time' } },
};

export const summaryChartConfig = {
  type: 'line',
  guide: {
    x: { label: { text: 'Time', padding: 40 } }, // custom label for X axis
    y: { label: { text: 'Score', padding: 40 } }, // custom label for Y axis
    showGridLines: '',
    color: {
      brewer: {
        Current: '#1F80CB',
        'Current hour': '#1F80CB',
        'Current day': '#1F80CB',
        'Current week': '#1F80CB',
        'Current month': '#1F80CB',
        'Current year': '#1F80CB',
        Previous: '#E9F2FA',
        'Previous hour': '#E9F2FA',
        'Previous day': '#E9F2FA',
        'Previous week': '#E9F2FA',
        'Previous month': '#E9F2FA',
        'Previous year': '#E9F2FA',
      },
    },
    interpolate: 'linear',
  },
  settings: {
    xTickWidth: 30,
    yTickWidth: 30,
  },
  color: 'period',
  x: 'adjustedTime',
  y: 'score',
  dimensions: { score: { type: 'measure' }, adjustedTime: { type: 'measure', scale: 'time' } },
  pluginsConfig: [
    {
      name: 'tooltip',
      config: {
        clickable: false,
        fields: ['score', 'time'],
        formatters: { score: { label: 'score' }, time: { label: 'time' } },
      },
    },
    { name: 'legend', config: { position: 'bottom', formatters: { period: { current: { title: 'hello' } } } } },
  ],
};

export const getTotalChartConfig = (usageData, timeRange?) => {
  const currentData = _.get(usageData, 'series.all.main', []);
  const previousData = _.get(usageData, 'series.all.previous', []);

  const processedCurrentData = _.map(currentData, item => ({
    time: new Date(item.time),
    adjustedTime: new Date(item.time),
    score: item.value.int,
    period: `Current ${timeRange ? timeFrameWords[timeRange] : ''}`,
  }));
  const processedPreviousData = _.map(previousData, item => ({
    time: new Date(item.time),
    adjustedTime: getAdjustedDate(timeRange, new Date(item.time), false),
    score: item.value.int,
    period: `Previous ${timeFrameWords[timeRange]}`,
  }));
  const processedData = [...processedCurrentData, ...processedPreviousData];

  return { ...summaryChartConfig, data: processedData };
};

export const getUsecaseChartConfig = usageData => {
  const usecaseData = _.get(usageData, 'series.use_cases', []);
  const processedData = _.flatMap(usecaseData, (data, usecase) =>
    _.map(data.main, item => ({
      time: new Date(item.time),
      score: item.value.int,
      usecase: _.replace(usecase, 'agentApp-', ''),
    }))
  );

  return { ...usecaseChartConfig, data: processedData };
};

export const timeFrameWords = {
  '1H': 'hour',
  '1D': 'day',
  '1W': 'week',
  '1M': 'month',
  '1Y': 'year',
};
